import { Mesh, MeshStandardMaterial, PlaneGeometry, RepeatWrapping, Vector2, FrontSide, BufferAttribute } from "three"
import { App } from "../App"
import { ColorRGB } from "../Utils/Utils"

export default class Ground {
    constructor() {
        this.instance = null
        this.black = null

        this.app = new App()

        this.init()
    }

    init() {
        const material = new MeshStandardMaterial({
            // color: new ColorRGB('#c0c0d7'),
            color: new ColorRGB('#fed258'),
            side: FrontSide,
            transparent: true,
            opacity: 0
        })

        const geometry = new PlaneGeometry(60, 40)
        this.instance = new Mesh(geometry, material)
        this.instance.rotation.set(-Math.PI / 2, 0, 0) 

        
        this.instance.receiveShadow = true

        const xRepeat = 3
        const yRepeat = 2
        
        // const materialDef = this.app.assetManager.getItem('ground-wood')
        const materialDef = this.app.assetManager.getItem('marble-polished-2')
        materialDef.map.wrapS = RepeatWrapping
        materialDef.map.wrapT = RepeatWrapping
        materialDef.map.repeat.set(xRepeat, yRepeat)
        
        // materialDef.map.rotation = Math.PI / 2
        
        material.map = materialDef.map
        
        if (materialDef.normal) {
            material.normalMap = materialDef.normal
            material.normalScale = new Vector2(0.8, 0.8)
            material.normalMap.repeat.set(xRepeat, yRepeat)
            material.normalMap.wrapS = RepeatWrapping
            material.normalMap.wrapT = RepeatWrapping
        }

        if (materialDef.alpha) {
            material.alphaMap = materialDef.alpha
        }

        if (materialDef.roughness) {
            // material.roughnessMap = materialDef.roughness
            // material.roughnessMap.repeat.set(xRepeat, yRepeat)
        }
        
        if (materialDef.ao) {
            this.instance.geometry.setAttribute('uv2', new BufferAttribute(this.instance.geometry.attributes.uv.array, 2));
            material.aoMap = materialDef.ao
            // material.aoMap.repeat.set(xRepeat, yRepeat)
        }
        
        if (materialDef.metalness) {
            material.metalnessMap = materialDef.metalness
            // material.metalnessMap.repeat.set(xRepeat, yRepeat)
        }
        
        material.metalness = 0.45
        material.roughness = 0.45

        if (this.app.debug.active) {
            const gf = this.app.debug.ui.addFolder('Ground').close()
            gf.addColor(material, 'color').name("Color")
            gf.add(material, 'roughness', 0, 1, 0.01)
            gf.add(material, 'metalness', 0, 1, 0.01)

            const params = { normal: 0.3 }
            gf.add(params, 'normal', 0, 1, 0.01).onChange((value) => {material.normalScale.set(value, value)})
        }
    }

   destroy() {
        this.black.material.dispose()
        this.black = null

        this.instance.material.map.dispose()
        this.instance.material.normalMap.dispose()
        this.instance.material.dispose()
        this.instance.geometry.dispose()
        
        this.instance = null   
        
        this.app = null   
    }
}
