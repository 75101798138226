import { roughness } from "three/webgpu";

export default [
    // Splashscreen
    {
        name: 'splashscreen',
        type: 'video',
        path: 'video/POP_Splashscreen_v3_512x512.webm',
    },

    // Models
    {
        name: 'opinel_v1',
        type: 'gltf',
        path: 'models/opinel_v1.glb',
        license: '',
        author: 'Ethan Gaudet-Traffy',
        url: ''
    },

    // Background textures
    {
        name: 'restaurant',
        type: 'texture',
        envmap: true,
        path: 'background/restaurant.jpg',
        license: '',
        author: '',
    },

    // Materials
    {
        name: 'marble-polished-2',
        type: 'material',
        path: 'materials/marble-polished-2',
        url: '',
        textures: {
            map: 'whgjcdtl_1K_Albedo.jpg',
            roughness: 'whgjcdtl_1K_Roughness.jpg',
            alpha: 'alpha.png'
        }
    },

    {
        name: 'bois-1',
        type: 'material',
        path: 'materials/bois-1',
        url: '',
        textures: {
            map: 'Opinel_1_BaseColor_1024px.webp',
            displacement: 'Opinel_1_Height.png',
            roughness: 'Opinel_1_ORM_512px.png',
            metalness: 'Opinel_1_ORM_512px.png',
            normal: 'Opinel_1_Normal_fixed_1024px.png'
        }
    },

    {
        name: 'bois-2',
        type: 'material',
        path: 'materials/bois-2',
        url: '',
        textures: {
            map: 'Opinel_2_BaseColor_1024px.webp',
            displacement: 'Opinel_2_Height.png',
            roughness: 'Opinel_2_ORM_512px.png',
            metalness: 'Opinel_2_ORM_512px.png',
            normal: 'Opinel_2_Normal_fixed_1024px.png'
        }
    },

    {
        name: 'bois-3',
        type: 'material',
        path: 'materials/bois-3',
        url: '',
        textures: {
            map: 'Opinel_3_BaseColor_1024px.webp',
            displacement: 'Opinel_3_Height.png',
            roughness: 'Opinel_3_ORM_512px.png',
            metalness: 'Opinel_3_ORM_512px.png',
            normal: 'Opinel_3_Normal_fixed_1024px.png'
        }
    },
    
    {
        name: 'bois-4',
        type: 'material',
        path: 'materials/bois-4',
        url: '',
        textures: {
            map: 'Opinel_4_BaseColor_1204px.webp',
            displacement: 'Opinel_4_Height.png',
            roughness: 'Opinel_4_ORM_512px.png',
            metalness: 'Opinel_4_ORM_512px.png',
            normal: 'Opinel_4_Normal_fixed_1024px.png'
        }
    },
    
    {
        name: 'bois-5',
        type: 'material',
        path: 'materials/bois-5',
        url: '',
        textures: {
            map: 'Opinel_5_BaseColor_1024px.webp',
            displacement: 'Opinel_5_Height.png',
            roughness: 'Opinel_5_ORM_512px.png',
            metalness: 'Opinel_5_ORM_512px.png',
            normal: 'Opinel_5_Normal_fixed_1024px.png'
        }
    },
    
    {
        name: 'bois-M2',
        type: 'material',
        path: 'materials/bois-M2',
        url: '',
        textures: {
            map: 'Opinel_M2_BaseColor_1024px.webp',
            displacement: 'Opinel_M2_Height.png',
            roughness: 'Opinel_M2_ORM_512px.png',
            metalness: 'Opinel_M2_ORM_512px.png',
            normal: 'Opinel_M2_Normal_1024px.png'
        }
    }
]
