import { App } from "../App"
import { BackSide, BoxGeometry, BoxHelper, BufferGeometry, CanvasTexture, Color, DoubleSide, Euler, FrontSide, Group, Line, LinearFilter, LinearSRGBColorSpace, LineBasicMaterial, MathUtils, Matrix4, Mesh, MeshBasicMaterial, MeshNormalMaterial, MeshStandardMaterial, NearestFilter, NoColorSpace, Plane, PlaneGeometry, Quaternion, Raycaster, SRGBColorSpace, Vector2, Vector3 } from "three"
import ModelUtils from "../Utils/ModelUtils"
import { CopyShader, DecalGeometry } from "three/examples/jsm/Addons.js"
import gsap from "gsap"
import { EventEmitter } from "../Utils/EventEmitter"
import { DEG2RAD } from "three/src/math/MathUtils.js"
import { Memory } from "../Utils/Memory"


// import { DecalGeometry } from 'three/addons/geometries/DecalGeometry.js';


// Opinel N°08

const PARTS = {
    handle: ['Manche'],
    blade: ['Lame'],
    lock: ['Bague_1', 'Bague_2', 'Vis']
}

export default class Opinel extends EventEmitter {
    constructor() {
        super()
        
        this.app = new App()

        this.blade = null
        this.handle = null
        this.lock = null

        this.bladeDecal = null

        this.closed = false
        this.locked = false

        this.init()
    }
    
    init() {
        const model = this.app.assetManager.getItem('opinel_v1')
        this.instance = typeof model.scene !== "undefined" ? model.scene : model
        this.instance.scale.set(1, 1, 1).multiplyScalar(0.25)
        this.instance.updateMatrix()
        
        this.bladeDecalSetupDone = false
        this.handleDecalSetupDone = false

        this.decalMaterial = null

        this.blade = new Group()
        this.blade.name = "blade"

        this.handle = new Group()
        this.handle.name = "handle"
        
        this.lock = new Group()
        this.lock.name = "lock"

        this.instance.add(this.blade, this.handle, this.lock)

        this.nodes = ModelUtils.useNodes(this.instance)

        for (const n in this.nodes) {
            this.nodes[n].castShadow = true
            this.nodes[n].receiveShadow = true
        }

        this.setupMaterials()
        this.setupNodes()
        this.setupGroups()

        this.close(0)

        gsap.delayedCall(1, () => {
            this.open(4)
            gsap.delayedCall(4.3, () => {
                this.setupDecals()
            })
        })       
    }

    setupNodes() {
        Object.values(PARTS).flat().map(part => {
            const node = this.nodes[part]
            console.log(`Opinel :: part ${part}`)
            if (node) {
                node.material = this.material
            }
        })
    }
    
    setupMaterials() {
        // Create new materials for each part
        this.material = new MeshStandardMaterial({
            roughness: 0.3,
            metalness: 1,
            normalScale: new Vector2(0.8, 0.8)
        })

        if (this.app.debug.active === true) {
            const mf = this.app.debug.ui.addFolder('Opinel').close()
            mf.add(this.material, "roughness", 0, 1, 0.01)
            mf.add(this.material, "metalness", 0, 1, 0.01)

            mf.add(this.material.normalScale, "x", 0, 1, 0.01).name("normal scale").onChange((value) => {
                this.material.normalScale = new Vector2(1, 1).multiplyScalar(value)
            })
        }

        // this.decalMaterial = new MeshNormalMaterial({ transparent: true, opacity: 0.5 })
        this.decalMaterial = new MeshBasicMaterial({
            side: FrontSide,
            transparent: true,
            opacity: 0.0,
            depthTest: true,
            depthWrite: false,
            polygonOffset: true,
            polygonOffsetFactor: -10
        })
    }

    setupDecals() {
        this.setupDecalForNode("Lame", new Vector3(-3.8, -0.61, 5), new Euler(0, 0, 0.015), 0xff0000)
        this.setupDecalForNode("Manche", new Vector3(4.4, 0.0, -5), new Euler(0, Math.PI, 0), 0xffff00)
    }
    
    setBladeEngravedText(text, font) {
        this.refreshDecalTexture(text, font, '#101010', "Lame")
    }

    setHandleEngravedText(text, font) {
        this.refreshDecalTexture(text, font, '#000000', "Manche")
    }

    setupDecalForNode(nodeName, offset, orientation, color) {
        const node = this.nodes[nodeName]
        
        // Invert world matrix to fix the orientation of the decal
        const worldRot = new Matrix4().extractRotation(this.instance.matrixWorld)
        
        const geom = node.geometry.clone()
        geom.applyMatrix4(this.instance.matrix)
        geom.computeBoundingBox()

        const nodeBB = geom.boundingBox
        const centerX = (nodeBB.max.x + nodeBB.min.x) * 0.5
        const centerY = (nodeBB.max.y + nodeBB.min.y) * 0.5

        const position = new Vector3(centerX, centerY, 0).add(offset)
        position.applyMatrix4(this.instance.matrix)
        
        const m = this.instance.matrix.clone().transpose()

        const size = new Vector3(2.5, 2.5, 2.5)

        const geometry = new DecalGeometry(node, position, orientation, size)

        const worldRotInverse = worldRot.clone().invert()
        geometry.applyMatrix4(worldRotInverse)

        if (geometry.attributes['position'].count === 0) {
            console.error('Decal cannot be projected')
            return
        }

        // Use placeholder material at the moment waiting for the user text
        const material = new MeshBasicMaterial({transparent: true, opacity: 0})
        
        const decal = new Mesh(geometry, material)
        decal.scale.set(1,1,1).divideScalar(this.instance.scale.x)
        decal.name = node.name + "-decal"

        if (this.app.debug.active) {
            const decalBoxHelper = new BoxHelper(decal, color);
            this.app.worldGroup.add(decalBoxHelper)
        }

        node.userData.decal = decal
        node.add(decal);
    }

    getBladeDecal() {
        return this.nodes["Lame"].userData.decal
    }
   
    getHandleDecal() {
        return this.nodes["Manche"].userData.decal
    }
   
    refreshDecalTexture(text, font, color, nodeName) {
        const decal = this.nodes[nodeName].userData.decal
        
        // Save mode - should never happen
        if (typeof decal === "undefined") {
            console.error("Decals not set, report bug")
            return
        }

        let material = decal.material

        // Dispose previous texture
        if (material.map && typeof material.map.dispose === "function") {
            material.map.dispose()
            material.map = null
        }

        // Remove placeholder material
        if (material.opacity === 0) {
            decal.material.dispose()
            decal.material = this.decalMaterial.clone()            
            
            material = decal.material
            material.opacity = 1
        }
        
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.width = 1024
        canvas.height = 1024
        
        context.fillStyle = color
        context.font = `3rem "${font}"`
        context.textAlign = "center"

        context.fillText(text, canvas.width * 0.5, canvas.height * 0.5)

        const decalTexture = new CanvasTexture(canvas)
        material.map = decalTexture
    }

    useMaterial(materialName) {
        this.material.map = this.app.assetManager.getItem(materialName).map
        
        this.material.normalMap = this.app.assetManager.getItem(materialName).normal
        this.material.normalMap.colorSpace = LinearSRGBColorSpace
        this.material.normalScale = new Vector2(0.8, 0.8)
        
        this.material.displaceMap = this.app.assetManager.getItem(materialName).displacement
        this.material.displaceMap.colorSpace = LinearSRGBColorSpace
        
        this.material.roughnessMap = this.app.assetManager.getItem(materialName).roughness
        this.material.roughnessMap.colorSpace = LinearSRGBColorSpace
        
        this.material.metalnessMap = this.app.assetManager.getItem(materialName).metalness
        this.material.metalnessMap.colorSpace = LinearSRGBColorSpace
    }

    setupGroups() {
        const isPartPresent = (part) => { return typeof this.nodes[part] !== "undefined" }
        const getPartNode = (partName) => { return this.nodes[partName] }

        this.handle.add(...PARTS.handle.filter(isPartPresent).map(getPartNode))
        this.blade.add(...PARTS.blade.filter(isPartPresent).map(getPartNode))
        this.lock.add(...PARTS.lock.filter(isPartPresent).map(getPartNode))
    }

    closeLock() {
        if (this.locked) {
            return
        }

        const camera = this.app.camera.instance
        const controls = this.app.camera.controls

        const radius = 10 // camera.position.length()
        const azimuth = 0 * DEG2RAD
        const polar = 0 * DEG2RAD
        const position = new Vector3().setFromSphericalCoords(radius, azimuth, polar)

        const tl = gsap.timeline({
            onStart : () => {
                controls.enabled = false
            },
            onUpdate : () => {
                // camera.position.lerp(position, tl.progress())
                controls.update()

            },
            onComplete : () => {
                this.locked = true
                controls.enabled = true
                this.trigger('locked')
            }
        })

        tl.to(this.lock.rotation, {x: DEG2RAD * 45, ease: "power4.out", duration: 1.5})
    }
    
    openLock() {
        if (!this.locked) {
            return
        }

        const camera = this.app.camera.instance
        const controls = this.app.camera.controls

        const radius = 10 // camera.position.length()
        const azimuth = 0 * DEG2RAD
        const polar = 0 * DEG2RAD
        const position = new Vector3().setFromSphericalCoords(radius, azimuth, polar)

        const orientation = new Quaternion()

        const tl = gsap.timeline({
            onStart : () => {
                controls.enabled = false
            },
            onUpdate : () => {
                // camera.position.lerp(position, tl.progress())
                controls.update()
            },
            onComplete : () => {
                this.locked = false
                controls.enabled = true
                this.trigger('unlocked')
            }
        })

        tl.to(this.lock.rotation, {x: DEG2RAD * 0, ease: "power4.out", duration: 1.5})        
    }

    open(duration) {
        if (!this.closed) {
            return
        }

        if (this.locked) {
            this.trigger('alert', [{message: "Tourner d'abord la sécurité pour pouvoir l'ouvrir"}])
        }

        const camera = this.app.camera.instance
        const cameraP0 = camera.position
        const zsgn = Math.sign(cameraP0.z)

        this.app.camera.controls.enabled = false

        const tl = gsap.timeline({
            onComplete : () => {
                this.app.camera.controls.enabled = true
                this.closed = false
                this.trigger('opened')
            }
        })

        duration = typeof duration === "number" ? duration : 3
        const dt1 = duration * 0.5
        const dt2 = duration - dt1        

        tl.to(this.instance.position, {y: 1.75, ease: "power4.in", duration: dt1})
        tl.to(this.instance.rotation, {z: -80 * DEG2RAD, ease: "power4.in", duration: dt1}, '<')
        tl.to(camera.position, {z:12 * zsgn, ease: "power4.in", duration: dt1}, '<')

        tl.to(this.blade.rotation, {z: 0, ease: "power4.out", duration: dt2})
        tl.to(this.instance.rotation, {z: 0, ease: "power4.out", duration: dt2}, '<')
        tl.to(camera.position, {z:cameraP0.z, ease: "power4.out", duration: dt2}, '<')
        tl.to(this.instance.position, {x: 0, ease: "power4.out", duration: dt2}, '<')
        tl.to(this.instance.position, {y: 0, ease: "power4.out", duration: dt2}, '<')

    }
    
    close(duration) {
        if (this.closed) {
            return
        }

        if (this.locked) {
            this.trigger('alert', [{message: "Tourner d'abord la sécurité pour pouvoir le fermer"}])
        }

        const camera = this.app.camera.instance
        const cameraP0 = camera.position
        const zsgn = Math.sign(cameraP0.z)

        this.app.camera.controls.enabled = false

        const tl = gsap.timeline({
            onComplete : () => {
                this.app.camera.controls.enabled = true
                this.closed = true
                this.trigger('closed')
            }
        })

        duration = typeof duration === "number" ? duration : 3
        const dt1 = duration * 0.67
        const dt2 = duration - dt1        

        tl.to(this.instance.rotation, {z: -DEG2RAD * 80, ease: "power4.in", duration: dt1})
        tl.to(this.blade.rotation, {z: DEG2RAD * 175, ease: "power4.in", duration: dt1}, '<')
        tl.to(this.instance.position, {y: 1.5, ease: "power4.in", duration: dt1}, '<')
        tl.to(camera.position, {z:10.5 * zsgn, ease: "power4.in", duration: dt1}, '<')
        
        tl.to(this.instance.rotation, {z: -DEG2RAD * 0, ease: "power4.out", duration: dt2})
        tl.to(this.instance.position, {x: -1, ease: "power4.out", duration: dt2}, '<')
        tl.to(this.instance.position, {y: 0, ease: "power4.out", duration: dt2}, '<')
        tl.to(camera.position, {z:cameraP0.z, ease: "power4.out", duration: dt2}, '<')
    }

    toggleOpenClose(duration) {
        this.closed ? this.open(duration) : this.close(duration)
    }

    destroy() {
        this.blade = null
        this.handle = null

        Memory.releaseObject3D(this.instance)

        this.decalMaterial.dispose()
        this.decalMaterial = null

        this.app = null
    }
}