const DEFAULT_CONFIG = {
    something : "somevalue"
}


export default class State {
    constructor() {
        this.init()
    }
    
    init() {
        this.value = DEFAULT_CONFIG.something
    }
    
    toJSON() {
        return {
            something: this.value
        }
    }
    
    destroy() {
        
    }
};
