import { Color, Vector2, Vector3 } from "three"

export class Utils {

    static worldToScreen(worldPt, camera, size) {
        const projPt = worldPt.clone()
        projPt.project(camera)
        return new Vector2(projPt.x * size.x * 0.5, -projPt.y * size.y * 0.5)
    }

    static Vector2FromVector3(vec3) {
        return new Vector3(vec3.x, vec3.y, 0)
    }

    // Azimuth [-PI; PI] from z axis
    // Polar [0; PI] from y axis
    static sphericalToCartesian(radius, azimuth, polar) {
        const z = radius * Math.sin(polar) * Math.cos(azimuth);
        const y = radius * Math.cos(polar);
        const x = radius * Math.sin(polar) * Math.sin(azimuth);
        return new Vector3(x, y, z);
    }

    static cartesianToSpherical(x, y, z) {
        const radius = Math.sqrt(x * x + y * y + z * z);
        const polar = Math.acos(y / radius); // φ
        const azimuth = Math.atan2(x, z);  // θ
        return { radius, azimuth, polar };
    }

    
    // Return difference in range [-π, π]
    static differenceBetweenAngles(angle1, angle2) {
        return ((angle2 - angle1) + Math.PI) % (2 * Math.PI) - Math.PI;
    }
    
}

window.u = Utils

export class ColorRGB extends Color {
    constructor(r, g, b) {
        super(r, g, b)

        this.convertLinearToSRGB()
    }

}