export default class Ease {
    
    static linear(t) {
        return t
    }

    static bezier(t) {
        return t * t * (3.0 - 2.0 * t)
    }
};
