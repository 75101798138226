export default class ModelUtils {
    static useNodes(object) {
        const nodeMap = {}

        object.traverse((node) => {
            if (node.isMesh) {
                nodeMap[node.name] = node
            }
        })

        return nodeMap
    }

    static listNodes(nodes) {
        Object.entries(nodes).forEach(n => console.log(n[0]))
    }

    static getNode(nodes, name) {
        return nodes.filter(node => node.name === name)[0]
    }
}
