import * as THREE from 'three'
import { RoomEnvironment } from 'three/examples/jsm/Addons.js';
import { BoxGeometry, Mesh, MeshStandardMaterial, PlaneGeometry, PointLight, SphereGeometry } from "three";
import { Memory } from './Memory';

export default class RoomEnvironmentGenerator {
    constructor(renderer, size = 256) {
        this.renderer = renderer;
    
        this.envMap = null;
        this.size = size; // Taille de la cubemap
    
        // Création de la scène de la pièce
        this.roomScene = new RoomEnvironment();
        
        const mat = new MeshStandardMaterial({})

        const balloon = new Mesh(new SphereGeometry(1.5, 32, 32), mat)
        balloon.position.set(-4,0,5)
        this.roomScene.add(balloon)
        
        const balloon2 = balloon.clone()
        balloon2.position.set(-4,0,-4)
        this.roomScene.add(balloon2)
        
        const balloon3 = balloon.clone()
        balloon3.position.set(4,0, 4)
        this.roomScene.add(balloon3)

        const balloon4 = balloon.clone()
        balloon4.position.set(4,0, -4)
        this.roomScene.add(balloon4)

        // Add some lights
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.15);
        this.roomScene.add(ambientLight);

        const pointLight = new PointLight(0xffffff, 150)
        pointLight.position.set(0, -1.8, 2)
        this.roomScene.add(pointLight)
        
        const windowLight1 = new THREE.RectAreaLight(0xffffff, 1)
        windowLight1.position.set(0, 0, 3)
        this.roomScene.add(windowLight1)

        const windowLight2 = new THREE.RectAreaLight(0xffffff, 1)
        windowLight2.position.set(0, 0, -3)
        this.roomScene.add(windowLight2)

        const windowLight3 = new THREE.RectAreaLight(0xffffff, 1)
        windowLight3.position.set(-3, 0, 0)
        this.roomScene.add(windowLight3)

        const windowLight4 = new THREE.RectAreaLight(0xffffff, 1)
        windowLight4.position.set(3, 0, 0)
        this.roomScene.add(windowLight4)
    }

    // Generate and return envmap
    // WARNING : the envmap should be released by the caller
    generateEnvMap() {
        // Utilisation de CubeCamera pour capturer la pièce en tant que cube map
        this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(this.size, {
            format: THREE.RGBAFormat,
            generateMipmaps: false,
            minFilter: THREE.LinearFilter,
            colorSpace: THREE.SRGBColorSpace
        });

        this.cubeRenderTarget.texture.mapping = THREE.CubeReflectionMapping
    
        this.cubeCamera = new THREE.CubeCamera(0.01, 100, this.cubeRenderTarget);
        this.cubeCamera.position.set(0, 0.5, 0)
        this.cubeCamera.update(this.renderer, this.roomScene);

        // this.exportSingleFaceToJPG(0)
        // this.exportCubeFacesToJPG()

        // PMREMGenerator pour créer une texture filtrée
        this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        // this.pmremGenerator.compileCubemapShader();
        this.pmremGenerator.compileEquirectangularShader();

        // Générer l'envMap avec PMREM pour filtrer et optimiser la texture
        // this.envMap = this.pmremGenerator.fromCubemap(this.cubeRenderTarget.texture).texture;
        // this.envMap = this.pmremGenerator.fromScene(this.roomScene, 0, 0.1, 1000)
        this.envMap = this.pmremGenerator.fromScene(this.roomScene)
    
        // this.exportEnvMapToJPG()

        return this.envMap;
    }
  
    exportSingleFaceToJPG(face = 0) {
        const pixels = new Uint8Array(this.size * this.size * 4); // RGBA

        // Lire la face positiveX (index 0)
        this.renderer.readRenderTargetPixels(this.cubeRenderTarget, 0, 0, this.size, this.size, pixels, 1);

        // Créer un canvas et afficher les pixels de cette face
        const canvas = document.createElement('canvas');
        canvas.style.zIndex = 999
        canvas.style.position = "absolute"
        canvas.style.width = canvas.width = 1024;
        canvas.style.height = canvas.height = 1024;
        canvas.style.top = "0px"
        canvas.style.left = "0px"
       
        const context = canvas.getContext('2d');
        const imageData = context.createImageData(this.size, this.size);
       
        for (let i = 0; i < pixels.length; i += 4) {
            imageData.data[i] = pixels[i]
            imageData.data[i + 1] = pixels[i+1]
            imageData.data[i + 2] = pixels[i+2]
            imageData.data[i + 3] = 255
        }
        context.putImageData(imageData, 0, 0);

        // Afficher le canvas pour tester la face
        // document.body.appendChild(canvas);

        const dataURL = canvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'cube_face_positiveX.jpg';
        link.click();
    }
 
    exportCubeFacesToJPG() {
        const size = this.size; // Taille d'une seule face du cube (par exemple 512x512)
        
        // Créer un canvas pour contenir toutes les faces
        const canvas = document.createElement('canvas');
        canvas.width = size * 3; // 3 faces par ligne
        canvas.height = size * 2; // 2 lignes de faces
      
        const context = canvas.getContext('2d');
      
        // Récupérer les pixels des 6 faces du cube
        const faces = ['positiveX', 'negativeX', 'positiveY', 'negativeY', 'positiveZ', 'negativeZ'];
        const offsets = [
          { x: 0, y: 0 }, // positiveX
          { x: size, y: 0 }, // negativeX
          { x: 2 * size, y: 0 }, // positiveY
          { x: 0, y: size }, // negativeY
          { x: size, y: size }, // positiveZ
          { x: 2 * size, y: size }, // negativeZ
        ];
      
        for (let i = 0; i < faces.length; i++) {
          // Lire les pixels pour chaque face
          const pixels = new Uint8Array(size * size * 4); // RGBA
          this.renderer.readRenderTargetPixels(this.cubeRenderTarget, 0, 0, size, size, pixels, i);
      
          // Créer une image temporaire à partir des pixels
          const imageData = context.createImageData(size, size);
          for (let j = 0; j < pixels.length; j += 4) {
            imageData.data[j] = pixels[j]; // Red
            imageData.data[j + 1] = pixels[j + 1]; // Green
            imageData.data[j + 2] = pixels[j + 2]; // Blue
            imageData.data[j + 3] = 255; // Alpha
          }
      
          // Dessiner cette face dans la position correspondante sur le grand canvas
          context.putImageData(imageData, offsets[i].x, offsets[i].y);
        }
      
        // Exporter le canvas en image JPG
        const dataURL = canvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'cube_faces.jpg';
        link.click();
    }

    exportEnvMapToJPG() {
        const size = this.size; // Taille d'une seule face du cube
      
        // Créer un canvas pour contenir toutes les faces (3 colonnes, 2 lignes)
        const canvas = document.createElement('canvas');
        canvas.width = size * 3; // 3 faces par ligne
        canvas.height = size * 2; // 2 lignes de faces
        const context = canvas.getContext('2d');
      
        // Récupérer les pixels des 6 faces du cube
        const faces = ['positiveX', 'negativeX', 'positiveY', 'negativeY', 'positiveZ', 'negativeZ'];
        const offsets = [
          { x: 0, y: 0 },       // positiveX
          { x: size, y: 0 },    // negativeX
          { x: 2 * size, y: 0 }, // positiveY
          { x: 0, y: size },    // negativeY
          { x: size, y: size }, // positiveZ
          { x: 2 * size, y: size } // negativeZ
        ];
      
        for (let i = 0; i < faces.length; i++) {
          // Lire les pixels pour chaque face
          const pixels = new Uint8Array(size * size * 4); // RGBA
          this.renderer.readRenderTargetPixels(this.cubeRenderTarget, 0, 0, size, size, pixels, i);
      
          // Créer une image temporaire à partir des pixels
          const imageData = context.createImageData(size, size);
          for (let j = 0; j < pixels.length; j += 4) {
            imageData.data[j] = pixels[j];       // Red
            imageData.data[j + 1] = pixels[j + 1]; // Green
            imageData.data[j + 2] = pixels[j + 2]; // Blue
            imageData.data[j + 3] = 255;         // Alpha
          }
      
          // Dessiner cette face dans la position correspondante sur le grand canvas
          context.putImageData(imageData, offsets[i].x, offsets[i].y);
        }
      
        // Exporter le canvas en image JPG
        const dataURL = canvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'envMap.jpg';
        link.click();
    }
      
    destroy() {
        this.cubeCamera = null

        Memory.releaseObject3D(this.roomScene)
        this.roomScene = null

        this.renderer = null

        this.cubeRenderTarget.dispose()
        this.cubeRenderTarget = null

        this.pmremGenerator.dispose()
        this.pmremGenerator = null
    }
  }
  