import { EventEmitter } from "../Utils/EventEmitter"

export default class SplashScreen extends EventEmitter {
    constructor(path) {
        super()

        this.path = path

        this.container = null
        
        this.onVideoLoadCompleteBound = this.videoLoadCompleteHandler.bind(this)
        this.onVideoErrorBound = this.videoErrorHandler.bind(this)
        this.onVideoEndedBound = this.videoEndedandler.bind(this)
        
        this.init()
    }
    
    init() {
        this.container = document.querySelector('.splashscreen')
        
        this.video = document.createElement("video")
        this.video.preload = "auto"
        this.video.muted = true
        this.video.classList.add("splashscreen-video")
    
        this.video.onloadeddata = this.onVideoLoadCompleteBound
        this.video.onerror = this.onVideoErrorBound
        
        this.video.src = this.path
    }

    videoLoadCompleteHandler(event) {
        this.container.insertBefore(this.video, this.container.firstChild)

        this.video.onended = this.onVideoEndedBound
        this.video.play()

        this.video.onclick= () => {
            console.log(`SplashScreen :: PAUSE`)
            this.video.pause()
        }

        this.trigger('playing')        
    }

    videoErrorHandler(event) {
        console.log(`SplashScreen :: media error (${this.video.error.message})`)
        console.log(`SplashScreen :: splash screen video load failed (src: ${this.path})`)
    }

    videoEndedandler(event) {
        this.container.removeChild(this.video)
        this.trigger('complete')
    }

    destroy() {
        this.onVideoLoadCompleteBound = null
        this.onVideoErrorBound = null
        this.onVideoEndedBound = null

        this.container = null
        this.video = null
    }
}