// import modules
import gsap from 'gsap'
import { App } from './App'

import './scss/style.scss'
import './scss/ui.scss'

const canvas = document.querySelector('canvas.webgl')
const app = new App({canvas, noSplashScreen:true})

const bladeFontSelector = document.querySelector('.blade-font-selector')
const handleFontSelector = document.querySelector('.handle-font-selector')

var fonts = ["Abril Fatface", "Courgette", "Lobster Two", "Rosario"];
let font = fonts[0]
let bladeText = ""
let handleText = ""

const selectorFontChange = (selector) => {
    var x = selector.selectedIndex;
    var y = selector.options;
    
    font = y[x].text

    // console.log(`script :: selectorFontChange ${font}`)
    selector.style.fontFamily = font

    if (selector.id === "blade-font-selector") {
        app.setBladeText(bladeText, font)
    }
    else {
        app.setHandleText(handleText, font)
    }
}

const selectorWheelHandle = (e) => {
    e.preventDefault(); // Empêche le défilement par défaut de la page
  
    const selector = e.target

    const currentIndex = selector.selectedIndex;
    const optionsLength = selector.options.length;
  
    if (e.deltaY < 0 && currentIndex > 0) {
      selector.selectedIndex = currentIndex - 1;
    } else if (e.deltaY > 0 && currentIndex < optionsLength - 1) {
      selector.selectedIndex = currentIndex + 1;
    }

    selectorFontChange(selector)
}

bladeFontSelector.addEventListener('wheel', selectorWheelHandle);
handleFontSelector.addEventListener('wheel', selectorWheelHandle);

const initSelector = (selector) => {
    for(var a = 0; a < fonts.length ; a++){
        var opt = document.createElement('option');
        opt.value = opt.innerHTML = fonts[a];
        opt.style.fontFamily = fonts[a];
        selector.add(opt);
    }
}

initSelector(bladeFontSelector)
initSelector(handleFontSelector)

bladeFontSelector.onchange = (e) => { return selectorFontChange(e.target) }
handleFontSelector.onchange = (e) => { return selectorFontChange(e.target) }

selectorFontChange(bladeFontSelector)
selectorFontChange(handleFontSelector)

const bladeTextInput = document.querySelector('.blade-text')
const bladeTextCharsLeft = document.querySelector('.blade-chars-left')


bladeTextInput.addEventListener("focus", function(event) {
    if (app.opinel.closed) {
        gsap.delayedCall(1, () => {
            app.openKnife()
        })
    }

    app.rotateOpinelToFrontSide()
})

bladeTextInput.addEventListener("input", function(event) {
    bladeText = event.target.value
    const length = bladeText.length
    const maxLength = event.target.maxLength
    bladeTextCharsLeft.innerHTML = `${length}/${maxLength} caractères`

    app.setBladeText(bladeText, font)
});


const handleTextInput = document.querySelector('.handle-text')
const handleTextCharsLeft = document.querySelector('.handle-chars-left')

handleTextInput.addEventListener("input", function(event) {
    handleText = event.target.value
    const length = handleText.length
    const maxLength = event.target.maxLength
    handleTextCharsLeft.innerHTML = `${length}/${maxLength} caractères`

    app.setHandleText(handleText, font)
});

handleTextInput.addEventListener("focus", function(event) {
    app.rotateOpinelToBackSide()
})

