import { AmbientLight, CameraHelper, DirectionalLight, DirectionalLightHelper, Group, HemisphereLight, PointLight, SpotLight, SpotLightHelper } from "three"
import { Memory } from "../Utils/Memory"
import { App } from "../App"
import { EventEmitter } from "../Utils/EventEmitter"
import gsap from 'gsap'

const CONFIG = {
    env: 0.26,
    ambient: 0.18,
    sun: 0.2,
    spot: 280.0,
    point: 1.8
}

export default class Lights extends EventEmitter {
    constructor() {
        super()

        this.app = new App()
        this.instance = new Group()
        this.init()
    }
    
    init() {
        this.app.scene.environment = this.app.envmap.texture
        // this.app.scene.environment = this.app.assetManager.items.envmap
        // this.app.scene.environmentRotation.set(0, -2.15, 0)
        this.app.scene.environmentIntensity = CONFIG.env

        const ambientLight = new AmbientLight(0xf0f0f0, 1)
        ambientLight.name = "ambient"
        ambientLight.color.convertLinearToSRGB()
        ambientLight.intensity = CONFIG[ambientLight.name]
        this.instance.add(ambientLight)
        
        const dirLight = new DirectionalLight(0xffffff, 1.2)
        dirLight.name = "sun"
        dirLight.color.convertLinearToSRGB()
        dirLight.intensity = CONFIG[dirLight.name]
        dirLight.position.set(0, 3, -2)
        dirLight.castShadow = true
        this.instance.add(dirLight)
        
        const spotLight = new SpotLight(0xe58e24, 400, 0, Math.PI/6, 0.8)
        spotLight.name = "spot"
        spotLight.intensity = CONFIG[spotLight.name]
        spotLight.color.convertLinearToSRGB()
        spotLight.position.set(3, 3, -4)
        spotLight.target.position.set(1, 0, -1)
        // spotLight.castShadow = true
        this.instance.add(spotLight)

        
        spotLight.shadow.mapSize.width = 1024
        spotLight.shadow.mapSize.height = 1024

        spotLight.shadow.camera.near = 0.01
        spotLight.shadow.camera.far = 40
        spotLight.shadow.camera.fov = 20


        const point = new PointLight(0xd7b893, 10, 0, 0)
        point.name = "point"
        point.color.convertLinearToSRGB()
        point.intensity = CONFIG[point.name]
        point.position.set(0, 3, 1)
        this.instance.add(point)

        // const camH = new CameraHelper(spotLight.shadow.camera)
        // this.instance.add(camH)

        
        if (this.app.debug.active) {
            const lf = this.app.debug.ui.addFolder("Lights").close()

            lf.add(this.app.scene, "environmentIntensity", 0, 1, 0.01).name("envmap").onChange(() => {
                this.trigger('envmapChanged')
            })
            lf.add(this.app.scene.environmentRotation, "y", -Math.PI, Math.PI, 0.01).name("Env rotation")
            
            lf.add(ambientLight, "intensity", 0, 1, 0.01).name("ambient")
            lf.addColor(ambientLight, "color").name("ambient color")

            
            lf.add(dirLight, "intensity", 0, 1, 0.1).name("dir")
            lf.addColor(dirLight, "color").name("dir color")
            
            const dirLightH = new DirectionalLightHelper(dirLight)
            dirLightH.visible = false
            dirLight.add(dirLightH)
            lf.add(dirLightH, "visible", false).name("dir helper")
            
            lf.add(spotLight, "intensity", 0, 500, 1).name("spot")
            lf.addColor(spotLight, "color").name("spot color")
            
            const spotLightH = new SpotLightHelper(spotLight)
            spotLightH.visible = false
            spotLight.add(spotLightH)
            lf.add(spotLightH, "visible", false).name("spot helper")

            lf.add(point, "intensity", 0, 2, 0.1).name("point")
            lf.addColor(point, "color").name("point color")
        }
    }
    
    destroy() {
        Memory.clearScene(this.instance)

        this.instance = null
    }
}